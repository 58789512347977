// This is a workaround for hiding freshchat in other page
// without flashing show and hide.

// The way is to hide the freshchat by default and then
// show it with only page including this component
//
// ref: https://stackoverflow.com/a/49878074/2017851
const ShowFreshChat = () => {
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
        #fc_frame {
          display: block !important;
        }
      `,
      }}
    />
  )
}

export default ShowFreshChat
