import { API_HOST } from "@/constants"
import { fetcher } from "@/libs/fetcher"
import { ItemSellOrderApi } from "@/types"
import useSWR from "swr"

export const useFeaturedItems = () => {
  const url = `${API_HOST}/v2/featuredItems`
  const { data, error } = useSWR(url, fetcher)
  
  return {
    data: (data?.data || []) as ItemSellOrderApi[],
    error,
    isLoading: !error && !data,
  }
}
