import ERC721Image from '@/components/ERC721Image'
import { useFeaturedItems } from '@/hooks/useFeaturedItems'
import { AnalyticsEvent, useAnalytics } from '@/libs/analytics'
import { formatRelativeTime } from '@/libs/datetime'
import { formatEther } from 'ethers/lib/utils'
import Link from 'next/link'
import React from 'react'

const FeaturedItemsSection: React.FC = () => {
  const { trackEvent } = useAnalytics()
  const { data: items } = useFeaturedItems()
  const panelClass =
    'm-2 bg-blue-200 shadow-lg rounded-lg overflow-hidden relative cursor-pointer hover:scale-105 transition-transform'

  if (!items || items.length < 3) {
    return <></>
  }

  const item1 = items[0]
  const item2 = items[1]
  const item3 = items[2]
  const link1 = `/collections/${item1.collection.slug}`
  const link2 = `/collections/${item2.collection.slug}`
  const link3 = `/collections/${item3.collection.slug}`

  if (!item1 || !item2 || !item3 || !link1 || !link2 || !link3) {
    return <></>
  }

  const panel1ClassName = `${panelClass} w-48 h-48 md:w-60 md:h-60 xl:h-72 xl:w-72 mt-12`
  const panel2ClassName = `${panelClass} w-32 h-32 md:w-40 md:h-40 xl:w-48 xl:h-48 mt-4`
  const panel3ClassName = `${panelClass} w-24 h-24 md:w-28 md:h-28 xl:w-36 xl:h-36`

  const text1ClassName =
    'text-white text-center absolute bottom-0 p-3 md:p-4 md:mb-6 text-md md:text-lg'
  const text2ClassName =
    'text-white text-center absolute bottom-0 p-2 md:p-4 md:p-4 md:mb-4 text-sm md:text-base'
  const text3ClassName =
    'text-white text-center absolute bottom-0 md:mb-4 p-2 md:p-3 text-xs md:text-sm font-medium'

  return (
    <div className="flex">
      <div className="flex flex-col items-end">
        {/* Rank 2 */}
        <Link key={item2.collectionAddress} href={link2}>
          <div
            className={panel2ClassName}
            onClick={() => {
              trackEvent(AnalyticsEvent.HeroFeaturedImageClicked, { index: 1 })
            }}
          >
            <ERC721Image
              src={item2.image || item2.collection.image || ''}
              alt={item2.collectionName}
              priority
            />
            <div className="absolute inset-x-0 bottom-0 h-3/4 bg-gradient-to-t from-slate-800"></div>
            <div className={text2ClassName}>
              <div className="hidden md:block">
                {item2.name} for sale at {formatEther(item2.paymentAmount)} ETH
              </div>
              <div className="block md:hidden">
                {item2.name} {formatEther(item2.paymentAmount)} ETH
              </div>
            </div>
            <div className="hidden md:block absolute text-center w-full bottom-0 p-2 text-xs text-slate-400">
              {formatRelativeTime(item2?.listedAt)}
            </div>
          </div>
        </Link>

        {/* Rank 3 */}
        <Link key={item3.collectionAddress} href={link3}>
          <div
            className={panel3ClassName}
            onClick={() => {
              trackEvent(AnalyticsEvent.HeroFeaturedImageClicked, { index: 2 })
            }}
          >
            <ERC721Image
              src={item3.image || item3.collection.image || ''}
              alt={item3.collectionName}
              priority
            />
            <div className="absolute inset-x-0 bottom-0 h-3/4 bg-gradient-to-t from-slate-800"></div>
            <div className={text3ClassName}>
              <div className="hidden md:block">
                {item3.name} for sale at {formatEther(item3.paymentAmount)} ETH
              </div>
              <div className="block md:hidden">
                {item3.name} {formatEther(item3.paymentAmount)} ETH
              </div>
            </div>
            <div className="hidden md:block absolute text-center w-full bottom-0 p-2 text-xs text-slate-400">
              {formatRelativeTime(item3?.listedAt)}
            </div>
          </div>
        </Link>
      </div>
      <div>
        {/* Rank 1 */}
        <Link key={item1.collectionAddress} href={link1}>
          <div
            className={panel1ClassName}
            onClick={() => {
              trackEvent(AnalyticsEvent.HeroFeaturedImageClicked, { index: 0 })
            }}
          >
            <div className="relative overflow-hidden">
              <ERC721Image
                src={item1.image || item1.collection.image || ''}
                alt={item1.collectionName}
                priority
              />
            </div>

            <div className="absolute inset-x-0 bottom-0 h-3/4 bg-gradient-to-t from-slate-800"></div>
            <div className={text1ClassName}>
              {item1.name} for sale at {formatEther(item1.paymentAmount)} ETH
            </div>
            <div className="hidden md:block absolute text-center w-full bottom-0 p-4 text-xs text-slate-400">
              {formatRelativeTime(item1?.listedAt)}
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default FeaturedItemsSection
