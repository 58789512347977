import Button from '@/components/Button'

import { AnalyticsEvent, useAnalytics } from '@/libs/analytics'
import { BLUESWEEP_TWITTER_FOLLOW_URL, MAX_SWEEP_ITEMS } from '@/constants'
import Icon from '@/components/Icon'

type Props = {
  onClickHeroButton: () => void
}
const HeroSection: React.FC<Props> = ({ onClickHeroButton }) => {
  const { trackEvent } = useAnalytics()

  return (
    <div>
      <h1 className="text-5xl leading-tight md:text-6xl md:leading-tight font-medium  text-slate-700">
        Easiest way to{' '}
        <span className="animate-bg text-transparent bg-clip-text animate-bg">
          Sweep NFTs
        </span>{' '}
        on Optimism ✨
      </h1>
      <div className="mt-4 text-lg text-slate-600">
        Save up to 76% gas for buying NFTs. Sweep up to {MAX_SWEEP_ITEMS} NFTs
        at once. <br />
        Discover the curated NFTs in the Optimism markets.
      </div>

      <div className="grid-cols-2 mt-8 flex space-x-4">
        <Button
          className="w-full max-w-[200px]"
          onClick={() => {
            onClickHeroButton()
            trackEvent(AnalyticsEvent.HeroButtonClicked)
          }}
        >{`Let's Sweep!`}</Button>

        <a href={BLUESWEEP_TWITTER_FOLLOW_URL} target="_blank" rel="noreferrer">
          <Button
            type="lineAnimate"
            className="px-6 font-medium min-w-max"
            onClick={() => {
              trackEvent(AnalyticsEvent.HeroTwitterButtonClicked)
            }}
          >
            <Icon name="twitter" size={18} className="mr-2" />
            <span className="hidden md:block">Follow our Twitter</span>
            <span className="block md:hidden">Twitter</span>
          </Button>
        </a>
      </div>
    </div>
  )
}

export default HeroSection
