import Link from 'next/link'
import { OrderType, CollectionDetailApi } from '@/types'
import { AnalyticsEvent, useAnalytics } from '@/libs/analytics'
import Icon from '@/components/Icon'
import OptimizedImage from '@/components/OptimizedImage'
import { formatLocaleTime, isBeforeNow } from '@/libs/datetime'
import Countdown from 'react-countdown'
import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from 'react-icons/ti'

const colClasses = {
  default: 'cursor-pointer items-center hover:text-black select-none',
  // SM only = 5 + 3 + 4 = 12
  collection: 'w-5/12 md:w-4/12 px-1',
  vol7d: '     w-3/12 md:w-2/12 px-1 text-right',
  floorPrice: 'w-4/12 md:w-2/12 lg:w-1/12 px-1 text-right',

  // MD only = (4 + 2 + 2) + (2 + 2) = 12
  volTotal: '      w-2/12 lg:w-1/12 px-1 text-right hidden md:block',
  volChangePct7d: 'w-2/12 lg:w-1/12 px-1 text-right hidden md:block',

  // >= LG only = (4 + 2 + 1) + (1 + 1) + (1 + 1 + 1) = 12
  volChangePct1d: 'w-1/12 px-1 text-right hidden lg:block',
  ownerTotal: 'w-1/12 px-1 text-right hidden xl:block',
  supplyTotal: 'w-1/12 px-1 text-right hidden lg:block',
}

const DEFAULT_ORDER_COLLECTION: OrderTypeValueHeader = {
  volSevenDay: OrderType.Sort,
  volTotal: OrderType.Sort,
  volChangePercentageOneDay: OrderType.Sort,
  volChangePercentageSevenDay: OrderType.Sort,
  floorPrice: OrderType.Sort,
  ownerTotal: OrderType.Sort,
  supplyTotal: OrderType.Sort,
  launchedAt: OrderType.Sort,
}

const tableHeader = [
  {
    id: 'volSevenDay',
    title: 'Vol 7d',
    class: colClasses.vol7d,
    sortable: true,
  },
  {
    id: 'volTotal',
    title: 'Total Vol',
    class: colClasses.volTotal,
    sortable: true,
  },
  {
    id: 'volChangePercentageOneDay',
    title: '%Vol 24h',
    class: colClasses.volChangePct1d,
    sortable: true,
  },
  {
    id: 'volChangePercentageSevenDay',
    title: '%Vol 7d',
    class: colClasses.volChangePct7d,
    sortable: true,
  },
  {
    id: 'floorPrice',
    title: 'Floor price',
    order: OrderType.ASC,
    class: colClasses.floorPrice,
    sortable: true,
  },
  {
    id: 'ownerTotal',
    title: 'Owners',
    class: colClasses.ownerTotal,
    sortable: true,
  },
  {
    id: 'supplyTotal',
    title: 'Items',
    class: colClasses.supplyTotal,
    sortable: true,
  },
]

type OrderTypeValueHeader = {
  volSevenDay: OrderType
  volTotal: OrderType
  volChangePercentageOneDay: OrderType
  volChangePercentageSevenDay: OrderType
  floorPrice: OrderType
  ownerTotal: OrderType
  supplyTotal: OrderType
  launchedAt: OrderType
}

type FeaturedCollectionsSectionProps = {
  title: string
  featuredCollections: CollectionDetailApi[]
  orderBy: 'launchedAt' | 'volSevenDay'
}
const FeaturedCollectionsSection: React.FC<FeaturedCollectionsSectionProps> = ({
  title,
  featuredCollections,
  orderBy,
}) => {
  const [sortedCollections, setSortedCollections] =
    useState<CollectionDetailApi[]>(featuredCollections)

  const [orderTypeValue, setOrderTypeValue] = useState<OrderTypeValueHeader>({
    volSevenDay: OrderType.Sort,
    volTotal: OrderType.Sort,
    volChangePercentageOneDay: OrderType.Sort,
    volChangePercentageSevenDay: OrderType.Sort,
    floorPrice: OrderType.Sort,
    ownerTotal: OrderType.Sort,
    supplyTotal: OrderType.Sort,
    launchedAt: OrderType.Sort,
  })

  const { trackEvent } = useAnalytics()

  useEffect(() => {
    setOrderTypeValue(() => ({
      ...DEFAULT_ORDER_COLLECTION,
    }))
    setSortedCollections(featuredCollections)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuredCollections])

  useEffect(() => {
    handleSort(orderBy)
  }, [sortedCollections])

  const handleSort = (key: keyof OrderTypeValueHeader) => {
    let result = sortedCollections.sort((left, right) => {
      if (key === 'launchedAt') {
        // push the null launched to the end
        setOrderTypeValue(() => ({
          ...DEFAULT_ORDER_COLLECTION,
          [key]: OrderType.DESC,
        }))

        if (!right.launchedAt) {
          return -1
        }
        if (!left.launchedAt) {
          return 1
        }

        return (
          DateTime.fromISO(right.launchedAt as string).toSeconds() -
          DateTime.fromISO(left.launchedAt as string).toSeconds()
        )
      } else {
        switch (orderTypeValue[key]) {
          case OrderType.ASC:
            setOrderTypeValue(() => ({
              ...DEFAULT_ORDER_COLLECTION,
              [key]: OrderType.DESC,
            }))
            return right[key] - left[key]

          case OrderType.DESC:
            setOrderTypeValue(() => ({
              ...DEFAULT_ORDER_COLLECTION,
              [key]: OrderType.ASC,
            }))
            return left[key] - right[key]

          default:
            setOrderTypeValue(() => ({
              ...DEFAULT_ORDER_COLLECTION,
              [key]: OrderType.DESC,
            }))
            return right[key] - left[key]
        }
      }
    })
    setSortedCollections(result)
  }

  return (
    <div>
      <h1 className="text-3xl text-center font-medium text-slate-700">
        {title}
      </h1>
      <div className="z-10 py-4 flex mt-12 md:px-8 text-sm text-slate-600 bg-white font-semibold sticky top-0">
        <div className={`${colClasses.collection} md:pl-4 lg:pl-10`}>
          Collection
        </div>
        {tableHeader.map((item) => {
          return (
            <div
              onClick={() => {
                trackEvent(AnalyticsEvent.Sorted, {
                  type: item.id,
                })
                handleSort(item.id as keyof OrderTypeValueHeader)
              }}
              key={item.id}
              className={`${item.class} ${colClasses.default} ${
                orderTypeValue[item.id as keyof OrderTypeValueHeader] ===
                OrderType.Sort
                  ? 'text-gray-500'
                  : 'text-black'
              }`}
            >
              <div className="flex items-center justify-end">
                <span className="mr-1">{item.title}</span>
                <OrderIcon
                  order={orderTypeValue[item.id as keyof OrderTypeValueHeader]}
                />
              </div>
            </div>
          )
        })}
      </div>
      <div className="divide-y mt-2 text-slate-600">
        {sortedCollections.map((featuredCollection, index) => {
          const isLaunched =
            !featuredCollection.launchedAt ||
            isBeforeNow(featuredCollection.launchedAt)

          return (
            <div key={featuredCollection.address}>
              <FeaturedCollectionRow
                collection={featuredCollection}
                index={index}
                isLaunched={isLaunched}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

type OrderIcon = {
  order: OrderType
}

const OrderIcon: React.FC<OrderIcon> = ({ order }) => {
  switch (order) {
    case OrderType.ASC:
      return <TiArrowSortedUp />
    case OrderType.DESC:
      return <TiArrowSortedDown />
    default:
      return <TiArrowUnsorted />
  }
}

type FeaturedCollectionRowProps = {
  index: number
  collection: CollectionDetailApi
  isLaunched: boolean
}
const FeaturedCollectionRow: React.FC<FeaturedCollectionRowProps> = ({
  index,
  collection,
  isLaunched,
}) => {
  const { trackEvent } = useAnalytics()
  const [showCountdown, setShowCountdown] = useState(!isLaunched)

  return (
    <Link href={`/collections/${collection.slug}`}>
      <a className="relative">
        {showCountdown && !isLaunched && collection.launchedAt && (
          <>
            <div
              className="absolute z-20 w-7/12 md:w-8/12 h-full right-0 inset-y-0 bg-white/80 flex justify-center items-center"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="text-slate-700 text-center">
                <div className="text-xl font-bold">SWEEP SOON</div>
                <div className="text-lg font-semibold">
                  <Countdown
                    date={new Date(collection.launchedAt)}
                    onComplete={() => setShowCountdown(false)}
                  />
                </div>
              </div>
            </div>
            {/* For prevent clicking into the collection */}
            <div
              className="absolute z-10 w-full h-full inset-0 opacity-0"
              onClick={(e) => e.stopPropagation()}
            />
          </>
        )}
        <div
          className="flex items-center md:px-8 py-4 font-medium cursor-pointer hover:bg-slate-50 active:bg-slate-100"
          onClick={() =>
            trackEvent(AnalyticsEvent.FeaturedCollectionClicked, {
              collection: collection.slug,
            })
          }
        >
          {/* Image & Name */}
          <div className={`${colClasses.collection} md:flex items-center`}>
            <div className="mr-4 hidden lg:block">{index + 1}.</div>
            <div className="md:mx-4 flex-shrink-0">
              <OptimizedImage
                className="rounded-md"
                src={collection.image || ''}
                width="60"
                height="60"
                alt={collection.name}
              />
            </div>
            <div>
              <div className="font-medium flex items-center truncate">
                <span className="truncate">{collection.name}</span>
                {collection?.verified && (
                  <div className="ml-1 mt-1 text-blue-500">
                    <Icon name="verify" />
                  </div>
                )}
              </div>
              <div className="text-slate-500 truncate">
                {!isLaunched && collection.launchedAt && (
                  <>
                    <div className="inline-block mr-1 font-normal text-sm">
                      <span className="hidden md:block">
                        Available at: {formatLocaleTime(collection.launchedAt)}
                      </span>
                      <span className="block md:hidden">
                        {formatLocaleTime(collection.launchedAt)}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Volume 7d  */}
          <div className={`${colClasses.vol7d}`}>
            Ξ {collection?.volSevenDay?.toFixed(2)}
          </div>

          {/* Total Volume */}
          <div className={`${colClasses.volTotal}`}>
            Ξ {collection?.volTotal?.toFixed(2)}
          </div>

          {/* Vol Change % 24h  */}
          <div
            className={`${colClasses.volChangePct1d} ${percentageTextClass(
              collection?.volChangePercentageOneDay,
            )}`}
          >
            {formatPercentage(collection?.volChangePercentageOneDay)}
          </div>

          {/* Vol Change % 7d  */}
          <div
            className={`${colClasses.volChangePct7d} ${percentageTextClass(
              collection?.volChangePercentageSevenDay,
            )}`}
          >
            {formatPercentage(collection?.volChangePercentageSevenDay)}
          </div>

          {/* Floor price  */}
          <div className={`${colClasses.floorPrice}`}>
            Ξ{' '}
            {collection?.floorPrice?.toFixed(5)
              ? parseFloat(collection?.floorPrice?.toFixed(5))
              : ''}
          </div>

          {/* Owners  */}
          <div className={`${colClasses.ownerTotal}`}>
            {collection?.ownerTotal?.toLocaleString('en-US')}
          </div>

          {/* Items  */}
          <div className={`${colClasses.supplyTotal}`}>
            {collection?.supplyTotal?.toLocaleString('en-US')}
          </div>
        </div>
      </a>
    </Link>
  )
}

function percentageTextClass(percentage: number) {
  if (!percentage || percentage === -1) {
    return ''
  }

  if (percentage > 0) {
    return 'text-green-500'
  }

  return 'text-rose-500'
}

function formatPercentage(percentage: number | undefined | null): string {
  if (percentage === 0) {
    return '0%'
  }

  // make -100% as empty to indicate data not enough instead of scaring users
  if (!percentage || percentage === -1) {
    return '-'
  }

  return (percentage * 100).toFixed(1) + '%'
}

export default FeaturedCollectionsSection
