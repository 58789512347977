import ShowFreshChat from '@/components/ShowFreshChat'
import { NEW_COLLECTION_PERIOD_HR } from '@/constants'
import { useFeaturedCollections } from '@/hooks/useFeaturedCollections'
import { diffHours } from '@/libs/datetime'
import FeaturedCollectionsSection from '@/sections/FeaturedCollectionsSection'
import FooterSection from '@/sections/FooterSection'
import HeaderSection from '@/sections/HeaderSection'
import HeroSection from '@/sections/HeroSection'
import FeaturedItemsSection from '@/sections/FeaturedItemsSection'
import type { NextPage } from 'next'
import { useRef } from 'react'

const Home: NextPage = () => {
  const { data: featuredCollections } = useFeaturedCollections()
  const featuredCollectionRef = useRef<HTMLDivElement>(null)

  // Only for testing
  // if (featuredCollections) {
  //   featuredCollections.forEach((f) => {
  //     if (f.collection.slug === 'optiorb') {
  //       f.collection.launchedAt = '2022-09-09T08:51:11.011Z'
  //     }
  //   })
  // }

  const handleScrollToFeaturedCollection = () => {
    featuredCollectionRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const isNewCollection = (launchedAtIso: string | undefined | null) => {
    // return false if the launchedAtIso is empty or in the past more than 24 hours
    if (!launchedAtIso) {
      return false
    }

    const launchedAt = new Date(launchedAtIso)
    const diffHr = diffHours(new Date(), launchedAt)
    return diffHr < NEW_COLLECTION_PERIOD_HR
  }

  // TODO: how should new collection be ordered
  const newCollections = featuredCollections?.filter((col) =>
    isNewCollection(col.launchedAt),
  )
  const existingCollections = featuredCollections?.filter(
    (col) => !isNewCollection(col.launchedAt),
  )

  return (
    <div className="m-4 lg:m-0 lg:container lg:mx-auto">
      <HeaderSection />

      <ShowFreshChat />

      <div className="mt-0 md:mt-4 lg:mt-12 xl:mt-20 2xl:mt-28 flex flex-wrap">
        <div className="lg:w-3/5 mt-8 xl:mt-12">
          <HeroSection onClickHeroButton={handleScrollToFeaturedCollection} />
        </div>
        <div className="lg:w-2/5 mt-8 lg:mt-0">
          <FeaturedItemsSection />
        </div>
      </div>

      <div className="mt-20 lg:mt-24 2xl:mt-28" ref={featuredCollectionRef}>
        {newCollections && newCollections.length > 0 && (
          <FeaturedCollectionsSection
            title="🚀 New Collections"
            featuredCollections={newCollections}
            orderBy="launchedAt"
          />
        )}
      </div>

      <div className="mb-24 mt-10 lg:mt-24 2xl:mt-28">
        <FeaturedCollectionsSection
          title="💎 Featured Collections"
          featuredCollections={existingCollections}
          orderBy="volSevenDay"
        />
      </div>

      <FooterSection />
    </div>
  )
}

export default Home
