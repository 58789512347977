import { API_HOST } from "@/constants"
import { fetcher } from "@/libs/fetcher"
import { CollectionDetailApi } from "@/types"
import useSWR from "swr"

export const useFeaturedCollections = () => {
  const url = `${API_HOST}/v2/featuredCollections`
  const { data, error } = useSWR(url, fetcher)
  
  return {
    data: (data?.data || []) as CollectionDetailApi[],
    error,
    isLoading: !error && !data,
  }
}
